import React from "react"

import Seo from "../../components/seo"
import { useLocation } from "@reach/router"
import styled from "styled-components"

import { FiSmartphone } from "@react-icons/all-files/fi/FiSmartphone"
import { FiMapPin } from "@react-icons/all-files/fi/FiMapPin"
import { FiMail } from "@react-icons/all-files/fi/FiMail"

const MainWrapper = styled.div`
  margin: 2rem 5vw;
  display: flex;
  flex-direction: column;
  background-color: #fffcf9;

  @media (min-width: 801px) {
    width: 500px;
    align-items: center;
    justify-content: center;
  }
`

const StyledHeader = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
`

const StyledSubHeader = styled.h2`
  font-size: 1.5rem;
  margin: 1rem 0;
  font-weight: bold;
`

const StyledItem = styled.a`
  color: black;
  font-size: 1.2rem;
  svg {
    color: var(--primary-high);
    margin-right: 0.5rem;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`


export default function Page() {
  const location = useLocation()
  var searchParams = new URLSearchParams(location.search)
  var pharmacy = searchParams.get("id")
  var SelectedPharmacy = ""

  if (pharmacy === "YDmtGdCaBPUQKJVMtpVL") {
    SelectedPharmacy = PharmacyList.CAO
  } else if (pharmacy === "rbFWZi4wPnen5gf4Rd9S") {
    SelectedPharmacy = PharmacyList.LUE
  } else if (pharmacy === "G10RIKYwQDGUjxKAS6Vm") {
    SelectedPharmacy = PharmacyList.AAA //Testapotheke1 ->nicht löschen Apple Account
  }  else if (pharmacy === "0z0ErRCD90czDhjV3k11") {
    SelectedPharmacy = PharmacyList.BSA // Beispielapothek
  }  
  


  if(SelectedPharmacy){
  return (
    <MainWrapper>
      <Seo title="Apotheken" description="Partnerapotheken" lang="de-CH" />

      <StyledHeader>Ihre Apotheke</StyledHeader>
      <Content>
        <StyledSubHeader>{SelectedPharmacy.name}</StyledSubHeader>
        <StyledItem
          href={"https://www.google.ch/maps/place/" + SelectedPharmacy.address}
          target="_blank"
          rel="noreferrer noopener"
        >
          {" "}
          <FiMapPin />
          {SelectedPharmacy.address}
        </StyledItem>

        <StyledItem href={"tel:" + SelectedPharmacy.phone}>
          {" "}
          <FiSmartphone />
          {SelectedPharmacy.phone}
        </StyledItem>

        <StyledItem href={"mailto:" + SelectedPharmacy.mail}>
          {" "}
          <FiMail />
          {SelectedPharmacy.email}
        </StyledItem>

      </Content>

    </MainWrapper>
  )
  }else{
      return(
    <MainWrapper>
      <h1> Keine Apotheke ausgwählt</h1>

    </MainWrapper>
      )
  }
}

const PharmacyList = {
  CAO: {
    name: "Central Apotheke Olten",
    address: "Baslerstrasse 72, 4600 Olten",
    phone: "+41 62 212 90 80",
    email: "central@apotheke-olten.ch",
  },
  LUE: {
    name: "Lünsche Apotheke",
    address: "Sternplatz 1, 58507 Lüdenscheid",
    phone: "+49 02351 4321026",
    email: " info@luensche-apotheke.de",
  },
  AAA: {
    name: "Toppharm Glattpark Apotheke",
    address: "Wright-Strasse 7, 8152 Glattpark (Opfikon)",
    phone: "044 315 10 00",
    email: "info@glattpark.apotheke.ch",
  },
  BSA: {
    name: "Beispielapotheke",
    address: "Wright-Strasse 7, 8152 Glattpark (Opfikon)",
    phone: "044 111 10 00",
    email: "info@beispielapotheke.ch",
  },
}
